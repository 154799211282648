/* fonts import */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* root variables */
:root {
  --primary-color: #f9bd34;
}

/* body, html and root imports */
*,
body,
html {
  font-family: 'Inter', sans-serif;
}

body {
  font-size: 1.6rem;
  background-color: var(--black);
  color: var(--gray-3);
}

a {
  color: var(--primary-color);
}

.grid_container {
  display: grid;
}

.flex_container {
  display: flex;
}

/* --------------------------------------------------------------- */

main {
  height: 90vh;
  width: 100vw;
  display: flex;
}

.screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92.5vw;
  height: 90vh;
  padding: 0 2rem 0 4rem;
  overflow-x: hidden;
  overflow-y: auto;
}

/* btn group */
.btn_group {
  display: grid;
  gap: 2.4rem;
  justify-content: flex-end;
}
.btn_group.two {
  grid-template-columns: auto auto;
}

.btn {
  padding: 1.2rem 2.4rem;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  background: #9a9c00;
  color: rgb(0, 0, 0);
}

.react-select {
  background: #000;
}

.role_status > div {
  margin-left: 10px;
}

.role_status .icon_active {
  background-color: green;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 5px;
}

.role_status .icon_notactive {
  background-color: rgb(212, 60, 73);
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 5px;
}

.contract_active_text {
  color: green;
}

.contract_pause_text {
  color: rgb(212, 60, 73);
}

.button_primary {
  background-color: 'var(--primary-color)';
  color: white;
  border: 2px solid;
  padding: 12px 20px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.role_container {
  display: flex;
  justify-content: space-between;
  border-radius: 1rem;
  padding: 2.2rem 4.4rem;
  background-color: #333333;
  margin-bottom: 20px;
}

.role_manage_container {
  border-radius: 1rem;
  padding: 2.2rem 4.4rem;
  background-color: #333333;
}

.role_manage_container .batch_mode {
  display: flex;
  align-items: center;
}

.role_manage_container .batch_mode input {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}

.role_manage_container .batch_mode label {
  font-size: 18px;
  display: inline-block;
}

.role_manage_item_container {
  margin-bottom: 20px;
}

.role_manage_item_container .role_manage_item label {
  display: block;
  width: 50%;
  margin-bottom: 10px;
}

.address_input {
  display: block;
  width: 50%;
  margin-bottom: 10px;
  font-size: 18px;
  border-radius: 1rem;
  padding: 10px 20px;
  background-color: black;
  color: white;
}


.role_manage_item_container .role_manage_item select {
  display: block;
  width: 50%;
  margin-bottom: 10px;
  font-size: 18px;
  border-radius: 1rem;
  padding: 10px 20px;
  background-color: black;
  color: white;
}

.role_manage_item_container .role_action_btn {
  padding: 10px 20px;
  border-radius: 1rem;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-right: 10px;
}

.role_manage_item_container .btn_grant {
  background-color: #2a73c5;
}

.role_manage_item_container .btn_grant:hover {
  background-color: #0078ff;
}

.role_manage_item_container .btn_revoke {
  background-color: #e45858;
}

.role_manage_item_container .btn_revoke:hover {
  background-color: #e91616;
}

.role_manage_item_container .btn_renounce {
  background-color: #ca8a04
}

.role_manage_item_container .btn_renounce:hover {
  background-color: #7a5606
}